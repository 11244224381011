define('ember-macro-helpers/create-class-computed', ['exports', 'ember-macro-helpers/get-value', 'ember-macro-helpers/collapse-keys', 'ember-macro-helpers/flatten-keys', 'ember-macro-helpers/-constants'], function (exports, _emberMacroHelpersGetValue, _emberMacroHelpersCollapseKeys, _emberMacroHelpersFlattenKeys, _emberMacroHelpersConstants) {
  'use strict';

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }return arr2;
    } else {
      return Array.from(arr);
    }
  }

  // import { getOwner } from '@ember/application';

  var WeakMap = Ember.WeakMap;

  var PROPERTIES = new WeakMap();

  function findOrCreatePropertyInstance(context, propertyClass, key, cp) {
    var propertiesForContext = PROPERTIES.get(context);
    if (!propertiesForContext) {
      propertiesForContext = new WeakMap();
      PROPERTIES.set(context, propertiesForContext);
    }

    var property = propertiesForContext.get(cp);
    if (property) {
      return property;
    }

    // let owner = getOwner(context);
    property = propertyClass.create( /* owner.ownerInjection(), */{
      key: key,
      context: context,
      nonStrings: Ember.Object.create()
    });

    propertiesForContext.set(cp, property);

    if (context instanceof Ember.Component) {
      context.one('willDestroyElement', function () {
        property.destroy();
      });
    }

    return property;
  }

  var BaseClass = Ember.Object.extend({
    // eslint-disable-next-line ember/no-observers
    computedDidChange: Ember.observer('computed', function () {
      var context = this.context,
          key = this.key,
          preventDoubleRender = this.preventDoubleRender;

      if (context.isDestroying) {
        // controllers can get into this state
        this.destroy();

        return;
      }

      if (preventDoubleRender) {
        return;
      }

      context.notifyPropertyChange(key);
    })
  });

  function resolveMappedLocation(key, i) {
    if (typeof key === 'string') {
      return 'context.' + key;
    } else {
      return 'nonStrings.' + i;
    }
  }

  exports['default'] = function (observerBools, macroGenerator) {
    return function () {
      for (var _len = arguments.length, keys = Array(_len), _key = 0; _key < _len; _key++) {
        keys[_key] = arguments[_key];
      }

      var _collapseKeysWithMap = (0, _emberMacroHelpersCollapseKeys.collapseKeysWithMap)(keys),
          collapsedKeys = _collapseKeysWithMap.collapsedKeys,
          keyMap = _collapseKeysWithMap.keyMap;

      function getOriginalArrayDecorator(key, i) {
        if (typeof key === 'string') {
          var originalKey = keys[keyMap[i]];
          if (originalKey.indexOf(_emberMacroHelpersConstants.ARRAY_EACH) !== -1 || originalKey.indexOf(_emberMacroHelpersConstants.ARRAY_LENGTH) !== -1) {
            return originalKey;
          }
        }
        return key;
      }

      var mappedKeys = [];

      function rewriteComputed(obj, key) {
        var _this = this;

        var mappedWithResolvedOberverKeys = mappedKeys.map(function (macro, i) {
          var shouldObserve = observerBools[i];
          if (shouldObserve) {
            macro = (0, _emberMacroHelpersGetValue['default'])({ context: _this, macro: macro, key: key });
          }
          return macro;
        });

        var cp = macroGenerator.apply(this, mappedWithResolvedOberverKeys);
        Ember.defineProperty(this, 'computed', cp);
      }

      var classProperties = {};

      collapsedKeys.forEach(function (key, i) {
        var shouldObserve = observerBools[i];
        if (!shouldObserve) {
          key = getOriginalArrayDecorator(key, i);
        }

        var mappedKey = resolveMappedLocation(key, i);

        mappedKeys.push(mappedKey);
        if (shouldObserve) {
          // eslint-disable-next-line ember/no-observers
          classProperties['key' + i + 'DidChange'] = Ember.observer(mappedKey, rewriteComputed);
        }
      });

      var ObserverClass = BaseClass.extend(classProperties, {
        // can't use rewriteComputed directly, maybe a bug
        // https://github.com/emberjs/ember.js/issues/15246
        onInit: Ember.on('init', function () {
          rewriteComputed.call(this);
        })
      });

      var cp = Ember.computed.apply(undefined, _toConsumableArray((0, _emberMacroHelpersFlattenKeys['default'])(keys)).concat([function (key) {
        var _this2 = this;

        var propertyInstance = findOrCreatePropertyInstance(this, ObserverClass, key, cp);

        var properties = collapsedKeys.reduce(function (properties, macro, i) {
          if (typeof macro !== 'string') {
            properties[i.toString()] = (0, _emberMacroHelpersGetValue['default'])({ context: _this2, macro: macro, key: key });
          }
          return properties;
        }, {});

        // eslint-disable-next-line ember/no-side-effects
        Ember.set(propertyInstance, 'preventDoubleRender', true);

        // eslint-disable-next-line ember/no-side-effects
        Ember.setProperties(propertyInstance.nonStrings, properties);

        // eslint-disable-next-line ember/no-side-effects
        Ember.set(propertyInstance, 'preventDoubleRender', false);

        return Ember.get(propertyInstance, 'computed');
      }])).readOnly();

      return cp;
    };
  };
});